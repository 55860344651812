<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Daftar Pembayaran Pasien</b>
      </div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/patients/add')">Tambah Pasien</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="row align-items-center mt-n3">
              <div class="col-md-6">
                <b-input-group>
                  <template #prepend>
                    <b-button
                      squared
                      @click="filterByPeriode"
                      variant="success">Cari</b-button>
                  </template>
                  <b-form-input
                    v-model="filter.start_date"
                    type="text"
                    placeholder="Tanggal Awal"
                    autocomplete="off"
                    readonly></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.start_date"
                      button-only
                      right
                      reset-button
                      locale="id"></b-form-datepicker>
                  </b-input-group-append>
                  <b-button
                    squared
                    variant="light">s/d</b-button>
                  <b-form-input
                    v-model="filter.end_date"
                    type="text"
                    placeholder="Tanggal Akhir"
                    autocomplete="off"
                    readonly></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="filter.end_date"
                      button-only
                      right
                      reset-button
                      locale="id"></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-md-3">
                <treeselect
                  v-model="filter.doctor_id"
                  :multiple="false"
                  :options="doctors"
                  placeholder="Cari Berdasar Dokter"
                  @select="filterByDoctor" />
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Pasien"
                    @keyup="filterByPatient"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div>
              <b-table
                striped
                hover
                class="mt-3"
                :items="items"
                :fields="fields">
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '15%' : '' }" />
                </template>
                <template #cell(final_amount)="data">
                  {{ parseInt(data.item.final_amount).toLocaleString('id-ID') }}
                </template>
                <template #cell(actions)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="$router.push({ path: '/patient/payment/' + data.item.patient_id })"><i class="fas fa-list-alt px-0"></i></b-button>
                </template>
              </b-table>
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"></b-pagination>
            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Treeselect from '@riophae/vue-treeselect'
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import { debounce } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js"
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Treeselect
  },

  data() {
    return {
      // filter
      filter: {
        name: "",
        doctor_id: '',
        start_date: "",
        end_date: ""
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "patient_id_card_number",
          label: "Kartu Pasien",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "patient_mobile_phone",
          label: "Nomor Telepon",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter Yang Menangani",
          sortable: true,
        },
        {
          key: "final_amount",
          label: "Total Biaya",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
    }
  },

  methods: {

    async pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      await this.pagination()
    },

    async pagination() {
      let filterParams = `&doctor_id=${this.filter.doctor_id}&patient_name=${this.filter.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate('payments', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.items = response.data
      this.totalRows = pagination.total
    },

    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect('doctors')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.doctors = response.data
        this.doctors.unshift({ label: 'Filter Berdasar Dokter', id: '', isDisabled: true })
      }
    },

    resetFilter() {
      this.filter.start_date = ''
      this.filter.end_date = ''
    },

    async filterByDoctor(evt) {
      await this.filter.doctor_id
      this.pagination()
    },

    filterByPatient() {
      debounce(() => {
        this.resetFilter()
        this.pagination()
      }, 500)
    },

    filterByPeriode() {
      this.pagination()
    }

  },

  watch: {
    'filter.doctor_id': function (newVal, oldVal) {
      if (typeof newVal === 'undefined') {
        this.filter.doctor_id = ''
        this.filterByDoctor()
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien" },
      { title: "Daftar Pembayaran" },
    ])
    this.getDoctorsOption()
    this.pagination()
  },

}
</script>
